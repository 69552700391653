.style {

  position: absolute;
  /* display: inline-block; */
  width: 400px;
  height: 400px;
  top: -150px;
  max-width: 100%;
  background: hsl(270, 17%, 98%);
  box-shadow: 30px 30px 30px rgba(79, 79, 79, 0.5);
  border-radius: 1000px;
}

.image {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-top: 7rem;
  transform: translate(-50%, -50%);
  height: 5rem;
  /* width: 20rem; */

}

.txtStyle {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-top: 7rem;
  transform: translate(-50%, -50%);
}