
.button {
  margin-left: 23rem;
  margin-right: 3rem;

}

.fiAlignment {
  margin-left: 2.7rem;
  text-align: left;
}

.vrFormInput {
  margin-top: 1rem;
}

.boxContainer {
  box-shadow: 0 0 10px #2270b44d;
  margin: auto;
  font-weight: 700;
  border-radius: 5px;
  font-size: 0.875rem;
}

.ltfWidth {
  width: 9rem;
  margin-right: .5rem;
}

.fifWidth {
  width: 30rem;
}

.flip {
  margin-left: 1.5rem;
}

.errorMsg {
  color: red
}

.d_flex {
  display: flex;
}

.ml5 {
  margin-left: 2.5rem;
}

.ml_15 {
  margin-left: -15rem;
}

.mr26 {
  margin-right: 26rem;
}

.mr24 {
  margin-right: 24rem;
}

.mr23-5 {
  margin-right: 23.5rem;
}

.mr23 {
  margin-right: 23rem;
}

.mr22 {
  margin-right: 22rem;
}

.mr18-3 {
  margin-right: 18.3rem;
}
table{
  font-family: arial, sans-serif;
  border-collapse: collapse;
 margin-bottom:1rem ;
 width: 50rem;
}
td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  width: 10rem;
}

tr:nth-child(even) {
  background-color: #dddddd;
}